<template>
  <div>
    <section class="m-1">
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="6"> </b-col>
        <b-col v-if="is_add" cols="6" md="2">
          <b-button block @click="onAddUser" variant="primary"
            >Add User</b-button
          ></b-col
        >
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(user_is_active)="data">
            {{ data.item.user_is_active == "Y" ? "Active" : "Disabled" }}
          </template>

          <template v-if="is_update" #cell(action)="data">
            <div class="text-nowrap">
              <b-button
                class="mr-1"
                @click="onEditItem(data.item)"
                size="sm"
                variant="outline-primary"
                >Edit</b-button
              >
            </div>
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { GetUserListService } from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      searchValue: null,

      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "org_name",
          label: "Organization",
          sortable: true,
        },
        {
          key: "first_name",
          label: "First Name",
          sortable: true,
        },
        {
          key: "last_name",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "email",
          label: "Email/Username",
          sortable: true,
        },
        {
          key: "mobile_number",
          label: "Contact No",
          sortable: true,
        },

        {
          key: "role_name",
          label: "Role",
          sortable: true,
        },
        {
          key: "user_is_active",
          label: "User Status",
          sortable: true,
        },

        {
          key: "action",
          label: "",
        },
      ],
      items: [],

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
    };
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getUserList();
  },
  methods: {
    async onAddUser() {
      this.$router.push({
        name: "userform",
      });
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getUserList();
      }, 1000);
    },
    async onEditItem(item) {
      this.$router.push({
        name: "userform",
        query: { user_id: item.user_id },
      });
    },
    async getUserList() {
      try {
        this.isBusy = true;
        const response = await GetUserListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
          isMaster: "Y",
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          this.items = this.items.filter((item) => item.role_id != 3);
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getUserList();
      });
    },
  },
};
</script>
